<template>
	<PopupMetier name="metier-cycliste">
		<ul slot="list__1">
			<li><router-link to="/metier-cycliste/securite">Sécurité</router-link></li>
			<li><router-link to="/metier-cycliste/inspection">Inspection</router-link></li>
			<li><router-link to="/metier-cycliste/qse">Qualité Sécurité Environnement</router-link></li>
			<li><router-link to="/metier-cycliste/environnement">Environnement</router-link></li>
			<li><router-link to="/metier-cycliste/securite-industrielle">Sécurité Industrielle</router-link></li>
		</ul>
		<ul slot="list__2">
			<li><router-link to="/metier-cycliste/production">Production</router-link></li>
			<li><router-link to="/metier-cycliste/expedition-maritime">Expéditions maritimes</router-link></li>
			<li><router-link to="/metier-cycliste/expedition-terrestre">Expéditions terrestres</router-link></li>
			<li><router-link to="/metier-cycliste/vern">Dépôt de Vern-Sur-Seiche</router-link></li>
		</ul>
		<ul slot="list__3">
			<li><router-link to="/metier-cycliste/arrets-et-gros-travaux">Arrêts et Gros travaux</router-link></li>
			<li><router-link to="/metier-cycliste/electricite">Electricité</router-link></li>
			<li><router-link to="/metier-cycliste/mecanique">Mécanique</router-link></li>
			<li><router-link to="/metier-cycliste/maintenance-courante">Maintenance courante</router-link></li>
			<li><router-link to="/metier-cycliste/disponibilite-et-methodes-generales">Disponibilité et Méthodes générales</router-link></li>
			<li><router-link to="/metier-cycliste/systemes-informatique-et-conduite">Systèmes informatiques et conduite</router-link></li>
		</ul>
	</PopupMetier>
</template>

<script>
	export default {
		components: {
			PopupMetier: () => import('@/components/PopupMetier.vue'),
		},
	}
</script>
